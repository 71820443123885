import React, {useEffect, useState} from 'react';
import icon_plush from '../images/Icon-plus.png';

import $ from 'jquery';
import axios from "axios";
import baseUrl from "../BaseUrl";
import popup_ok from "../images/dualgo_ingame_img/ingame_popupOK.png";
import popup_questions from "../images/dualgo_ingame_img/Questions_file.svg";

function MyAvatar( ) {
    const [exchange, setExchange] = useState([]);
    const [selected, setSelected] = useState([]);
    const [profileAvatar, setProfileAvatar] = useState();
    const [showPopup, setShowPopup] = useState(false);
    const [avatar_count, setAvatarCount] = useState(0);
    const [totalMoney, setTotalMoney] = useState('');
    const [exchangeAvatar, setExchangeAvatar] = useState('');


    const featchAvatar = async () => {
        try {
            const response = await axios.get(baseUrl + 'my_page/wallet', {
               headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if (response.status === 200) {
                sessionStorage.setItem('avatar_id', response.data.profile_avatar);
                setExchange(response.data.exchange);
                setSelected(response.data.selected);
                setProfileAvatar(response.data.profile_avatar);
            }
        } catch (error) {

        }
    };

    const calculateMoney = () => {
        setShowPopup(false);
        var money = avatar_count * 70000000;
        money =  parseInt(money.toString().replace(/0/g, ''), 10) ;
        setTotalMoney(money);
    };

    async function exchangeProfileAvatar() {
        setTotalMoney('');

        const data = {
            avatar_id: exchangeAvatar,
            d_avatar_count: avatar_count
        };


        try {
            const response = await axios.post(baseUrl + 'my_page/change_profile_avatar/', data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if(response.status === 200){
                sessionStorage.clear();
                window.location.reload();
            }
        } catch (e) {
        }
    }


    async function changeProfileAvatar(avatar_id, d_avatar_count=null) {
        if(d_avatar_count) {
            setShowPopup(true);
            setAvatarCount(d_avatar_count);
            setExchangeAvatar(avatar_id);
            return ;
        }

        setTotalMoney('');

        const data = {
            avatar_id: avatar_id
        };

        try {
            const response = await axios.post(baseUrl + 'my_page/change_profile_avatar/', data, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if(response.status === 200){
                sessionStorage.clear();
                window.location.reload();
            }
        } catch (e) {
        }
    }

    useEffect(() => {
        $('.ServiceGameCenterWarp').css('height', 'auto');
        $('.shop_col_type1').css('margin-bottom', 'unset');
        $('.shop_card_tit').css('margin', '13px 0 22px 0');
        $('.shop_card_item').css('height', '250px');
        $('.GameCenterwarp_inner').css({
            'height': 'auto',
            'padding-bottom': '200px'
        });
    }, [exchange]);

    useEffect(() => {
        featchAvatar();
    }, []);

    return (
        <>
            <div className="shop_section_2">
                <div className="tab_box">
                    <div className="tabA">
                        <div className="my_section_tit_box_inner">
                            <em className="section_tit" style={{fontSize: '26px'}}>
                                아바타 <img loading="lazy" src={icon_plush} style={{padding: '8px 0px'}}/>
                                <a className="section_tit_box_underL"
                                   style={{width: '40px', margin: '37px -100px'}}></a>
                                <a className="section_tit_box_underL2"
                                   style={{width: '67px', margin: '37px -63px'}}></a>
                            </em>
                        </div>
                    </div>
                    <div id="tab2" className="shop-tab-content">
                        {exchange.map((item, index) => {

                            return (
                                <div key={index} className="shop_card_item shop_col_type1">
                                    <div className="card card_square">
                                        <span className="card_img_box_game">
                                            <img loading="lazy"
                                                 src={require('../images/shop_img/' + item.d_avatarid + '.gif')}
                                                 onClick={() => changeProfileAvatar(item.d_avatarid, item.d_avatar_count)}/>
                                        </span>
                                        <em className="shop_card_tit">{item.d_avatar_name}</em>
                                        <em className="shop_card_tit" style={{fontSize: '18px'}}>{item.d_avatar_count * 7}억 골드(냥)</em>

                                        <div>
                                            <button className="buybutton"
                                                    onClick={() => changeProfileAvatar(item.d_avatarid, item.d_avatar_count)}>사용
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            );

                        })}

                        {selected.map((item, index) => {

                            return (
                                <div key={index} className="shop_card_item shop_col_type1">
                                    <div className="card card_square">
                                        <span className="card_img_box_game">
                                            <img loading="lazy"
                                                 src={require('../images/shop_img/' + item.d_avatarid + '.gif')}
                                                 onClick={() => changeProfileAvatar(item.d_avatarid)}/>
                                        </span>
                                        <em className="shop_card_tit">{item.d_avatar_name}</em>
                                        {
                                            profileAvatar === item.d_avatarid ?
                                                <div>
                                                    <button style={{backgroundColor: '#4e4e4e', color: '#ffffff'}}
                                                            className="buybutton">선택됨
                                                    </button>
                                                </div>
                                                :
                                                <div>
                                                    <button className="buybutton"
                                                            onClick={() => changeProfileAvatar(item.d_avatarid)}>선택
                                                    </button>
                                                </div>
                                        }

                                    </div>
                                </div>
                            );

                        })}

                    </div>

                    {exchange.length > 0 && (
                        <div className="shop_tab_center_bar" style={{position: 'relative', height: '40px'}}></div>)}

                    <div id="tab4" className="tabcontentGame" style={{display: 'none'}}></div>
                </div>
            </div>

            {showPopup && (
                <div className="my_wallet_popup">
                    <div className="my_wallet_popup_content">
                        <div className="message-popup-content" style={{marginTop: '0'}}>
                            <img src={popup_questions} className="animated rubberBand" alt="OK"/>
                            <span style={{fontSize: '16px', marginTop: '20px'}}>구매한 상품을 사용하시면 환불이 불가합니다.</span>
                            <span style={{fontSize: '16px'}}>사용하시겠습니까?</span>
                        </div>

                        <div style={{display: "flex", justifyContent: 'center', width: '100%', gap: '20px'}}>
                            <div onClick={calculateMoney} className="wallet-btn-web">
                                확 인
                            </div>

                            <div onClick={() => setShowPopup(false)} className="wallet-btn-web">
                                취 소
                            </div>
                        </div>

                    </div>
                </div>
            )}


            {totalMoney && (
                <div className="my_wallet_popup">
                    <div className="my_wallet_popup_content">
                        <div className="message-popup-content" style={{marginTop: '0'}}>
                            <img src={popup_ok} className="animated rubberBand" alt="OK"/>
                            <span style={{fontSize: '16px', marginTop: '20px'}}>{totalMoney}억 골드(냥)을 획득했습니다.</span>
                        </div>
                        <div onClick={exchangeProfileAvatar} className="message-pass-btn-web">
                            확 인
                        </div>
                    </div>
                </div>
            )}

        </>

    );
}

export default MyAvatar;
