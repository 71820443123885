import React, {useEffect, useState} from "react";
import $ from "jquery";
import icon_button from "../images/mypage_img/Icon-button.png";
import axios from "axios";
import SubHeader from "../home/subHeader";
import {useNavigate} from "react-router-dom";
import BaseUrl from "../BaseUrl";
import SubFooter from "../home/subFooter";
import MembershipCreateOk from "./membershipCreateOk";

function MembershipCreate() {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [pwOk, setPwOk] = useState('');
    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');
    const [membershipCreated, setMembershipCreated] = useState(false);


    const [isIdValid, setIsIdValid] = useState(false);
    const [isNicknameValid, setIsNicknameValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState('');

    const handleIdChange = (e) => {
        let inputVal = e.target.value.replace(/\s/g, '');
        let timer;

        // Remove special characters
        inputVal = inputVal.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=ㄱ-ㅎㅏ-ㅣ|가-힣]/gi, '');

        // Disallow starting with a number
        if (/^\d/.test(inputVal)) {
            inputVal = inputVal.slice(1);
        }

        setId(inputVal);
        $("#result_id_msg").html("");

        if (inputVal.length < 5) {
            $("#idbox").height('100px');
            $("#idbox2").height('100px');
            $("#idbox3").css("line-height", "99px");
            $("#result_id_msg").html('<span id="id_msg" style="color:#cd642b;">최소 5글자 ~ 최대 11글자.</span>');
            return;
        }

        clearTimeout(timer);

        // Set timer to make API call after 10 seconds
        if (inputVal) {
            timer = setTimeout(async () => {
                $("#idbox").height('100px');
                $("#idbox2").height('100px');
                $("#idbox3").css("line-height", "99px");

                try {
                    await axios.get(BaseUrl + 'check_user_id/?user_id=' + inputVal);
                    $("#result_id_msg").html('사용 가능한 ID입니다.');
                    setIsIdValid(true);
                } catch (error) {
                    $("#result_id_msg").html('<span id="id_msg" style="color:#cd642b;">중복된 ID입니다</span>');
                    setIsIdValid(false);
                }
            }, 100); // 10 seconds
        } else {
            setIsIdValid(false);
        }
    };

    const validatePassword = (password) => {
      const rules = [
        { regex: /.{6,15}/ },  // Password length between 6 and 15 characters
        { regex: /[A-Za-z]/ },    // At least one letter
        { regex: /[0-9]/ },    // At least one digit
        { regex: /[!@#$%^&*(),.?":{}|<>]/ }  // At least one special character
      ];

      for (let rule of rules) {
        if (!rule.regex.test(password)) {
          return false;
        }
      }

      return true;
    };



    const handlePwChange = (e) => {
        var inputVal = e.target.value.replace(/\s/g, '');
        inputVal = inputVal.replace(/[ \ㄱ-ㅎㅏ-ㅣ|가-힣 ]/gi, '');
        setPw(inputVal);

        if (validatePassword(inputVal)) {
            $("#result_pw_msg").html("");
            $("#pw_box").height('76px');
            $("#pw_box2").height('76px');
            $("#pw_box3").css("line-height", "unset");
        } else {
            $("#pw_box").height('100px');
            $("#pw_box2").height('100px');
            $("#pw_box3").css("line-height", "99px");
            $("#result_pw_msg").html('<span style="color:#cd642b;">비밀번호는 최소 6글자, 최대 15글자의 영문 + 숫자 + 특수문자만 입력 가능합니다.</span>');
        }

    };

    const handlePwOkChange = (e) => {
        const inputVal = e.target.value.replace(/\s/g, '');
        const result = inputVal.replace(/[\ㄱ-ㅎㅏ-ㅣ|가-힣]/gi, '');
        setPwOk(result);

        if (result){
            $("#pw_ok_box").height('100px');
            $("#pw_ok_box2").height('100px');
            $("#pw_ok_box3").css("line-height", "99px");

            if (pw === result) {
                $("#result_pw_ok_msg").html("<span style=\"color:#979797;\">일치 합니다.</span>");
            } else {
                $("#result_pw_ok_msg").html("<span style=\"color:#cd642b;\">비밀번호와 비밀번호 확인이 서로 다릅니다.</span>");
            }
        } else {
            $("#result_pw_ok_msg").html("");
            $("#pw_ok_box").height('76px');
            $("#pw_ok_box2").height('76px');
            $("#pw_ok_box3").css("line-height", "unset");
        }


    };

    const handleNicknameChange = (e) => {
        const inputVal = e.target.value.replace(/\s/g, '');
        if (inputVal.length > 6){
            return ;
        }
        setNickname(inputVal);
        let timer;

        clearTimeout(timer);

        if (inputVal.length > 1){
            timer = setTimeout(async () => {
                $("#nicknamebox").height('100px');
                $("#nicknamebox2").height('100px');
                $("#nicknamebox3").css("line-height", "99px");

                try {
                    await axios.get(BaseUrl + 'check_nickname/?nickname=' + inputVal);

                    $("#result_nickname_msg").html('사용 가능한 닉네임입니다.');
                    setIsNicknameValid(true);

                } catch (error) {
                    $("#result_nickname_msg").html('<span id="id_msg" style="color:#cd642b;">이미 사용중인 닉네임입니다.</span>');
                    setIsNicknameValid(false);
                }
            }, 100);
        } else {
            if(inputVal.length === 1){
                $("#nicknamebox").height('100px');
                $("#nicknamebox2").height('100px');
                $("#nicknamebox3").css("line-height", "99px");
                setIsNicknameValid(false);
                $("#result_nickname_msg").html('<span id="id_msg" style="color:#cd642b;">닉네임은 최소 2글자~최대 6글자를 입력해 주세요.</span>');
            }
            else {
                $("#nicknamebox").height('76px');
                $("#nicknamebox2").height('76px');
                $("#nicknamebox3").css("line-height", "unset");
                setIsNicknameValid(false);
                $("#result_nickname_msg").html('<span></span>');
            }

        }

    };


    const handleEmailChange = async (e) => {
        var email = e.target.value.replace(/\s/g, '');
        email = email.replace(/[^A-Za-z0-9@._-]/g, '');
        setEmail(email);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const is_valid = emailPattern.test(email);


        $("#emailbox").height('100px');
        $("#emailbox2").height('100px');
        $("#emailbox3").css("line-height", "99px");

        if (is_valid) {
            try {
                await axios.get(BaseUrl + "check_email/?email=" + email);
                
                $("#result_email_msg").html('사용 가능한 이메일입니다.');
                setIsEmailValid('');
            } catch (error) {
                $("#result_email_msg").html('<span id="id_msg" style="color:#cd642b;">이미 사용 중인 이메일입니다.</span>');
                setIsEmailValid("이미 사용 중인 이메일입니다.");
            }
        } else {
            setIsEmailValid("잘못된 이메일 형식입니다.");
            $("#result_email_msg").html('<span id="id_msg" style="color:#cd642b;">잘못된 이메일 형식입니다.</span>');
        }

    };

    const handleSubmit = async (e) => {
        if (id.length === 0) {
            return alert('아이디를 입력해 주세요.');
        }else if (id.length < 5) {
            return alert('아이디는 최소 5글자~최대 11글자를 입력해 주세요.');
        }
        else if (!isIdValid) {
            return alert('중복된 ID입니다.');
        }
        else if (!pw) {
            return alert('비밀번호를 입력해 주세요.');
        }
        else if(!validatePassword(pw)) {
            return alert('비밀번호는 최소 6글자, 최대 15글자의 영문 + 숫자 + 특수문자만 \n입력 가능합니다.');
        }
        else if (!pwOk){
            return alert('비밀번호 확인을 입력해 주세요.');
        }
        else if (pw !== pwOk) {
            return alert('비밀번호와 비밀번호 확인이 서로 다릅니다.');
        }
        else if(!nickname) {
            return alert('닉네임을 입력해 주세요.');
        }
        else if(nickname.length < 2) {
            return alert('닉네임은 최소 2글자~최대 6글자를 입력해 주세요.');
        }
        else if (!isNicknameValid) {
            return alert('이미 사용중인 닉네임입니다.');
        }
        else if (!email) {
            return alert('이메일 주소를 입력해 주세요.');
        }
        else if (isEmailValid) {
            return alert(isEmailValid);
        }


        var data = {
            user_id: id,
            password: pw,
            conform_password: pwOk,
            nickname: nickname,
            email: email,
            token: token
        };


        try {
            const response = await axios.post(BaseUrl + "membership_create/", data);
            const token = response.data.access;
            localStorage.setItem('token', token);

            const now = new Date();
            now.setTime(now.getTime() + 12 * 60 * 60 * 1000);
            document.cookie = `token=${token}; path=/; Secure; SameSite=Strict`;
            document.cookie = `tokenTime=time; expires=${now.toUTCString()}; path=/; Secure; SameSite=Strict`;
            window.location.reload();
        } catch (error) {
            if (error.response.status === 400) {
                navigate('/membership');
                window.location.reload();
                alert(error.response.data.message);
            } else {
                navigate('/membership');
                window.location.reload();
                alert('internal server error');
            }
        }
    };


    useEffect(() => {
        const [navigationEntry] = performance.getEntriesByType('navigation');

        if (navigationEntry.type === "back_forward") {
            window.history.pushState(null, "", "/membership");
            window.location.reload();
        }


        if (!token){
            navigate('/membership');
        }

        if(localStorage.getItem('token')){
            setMembershipCreated(true);
        }

    }, []);


    return (
        <div style={{backgroundColor: '#f5f5f5'}}>
            <SubHeader bodyColor={"white"}/>

            {!membershipCreated && (
                <>
                    <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                        <div className="TopMysection">
                            <div className="TopMysection_inner">
                                <div className="TopMysection_title">회원가입</div>
                            </div>
                        </div>
                    </div>

                    <div className="Centerwarp">
                        <div className="Centerwarp_inner" style={{height: '800px'}}>
                            <div className="subMaintitleBox">
                                <div className="subMainGrayBox"></div>
                                <div className="subMaintitle"><em>정보입력</em></div>
                            </div>
                            <div className="My_contentsBox">

                                <div className="My_contents" id="idbox">
                                    <div className="My_contents_innerleftBox" id="idbox2">
                                        <em style={{paddingLeft: '60px'}} id="idbox3">아 이 디</em>
                                    </div>
                                    <div className="My_passwardBox">
                                        <div className="passward_inputBox">
                                            <input
                                                type="text"
                                                id="id"
                                                name="id"
                                                placeholder="최소 5글자 ~ 최대 11글자"
                                                style={{imeMode: 'disabled'}}
                                                maxLength="11"
                                                autoComplete="off"
                                                value={id}
                                                required="true"
                                                onChange={handleIdChange}
                                                // onBlur={handleIdChangeBlur}
                                            />
                                        </div>
                                    </div>
                                    <em className="result_msg" id="result_id_msg"></em>
                                </div>

                                <div className="My_contents" id="pw_box">
                                    <div className="My_contents_innerleftBox" id="pw_box2">
                                        <em style={{paddingLeft: '60px'}} id="pw_box3">비밀번호</em>
                                    </div>
                                    <div className="My_passwardBox">
                                        <div className="passward_inputBox">
                                            <input
                                                type="password"
                                                id="pw"
                                                name="pw"
                                                style={{imeMode: 'disabled'}}
                                                maxLength="15"
                                                placeholder="최소 6글자 ~ 최대 15글자"
                                                autoComplete="new-password"
                                                value={pw}
                                                onChange={handlePwChange}
                                                // onBlur={handlePwBlur}
                                                required="true"
                                            />
                                        </div>
                                    </div>
                                    <em className="result_msg" id="result_pw_msg"></em>
                                </div>

                                <div className="My_contents" id="pw_ok_box">
                                    <div className="My_contents_innerleftBox" id="pw_ok_box2">
                                        <em style={{paddingLeft: '60px'}} id="pw_ok_box3">비밀번호 확인</em>
                                    </div>
                                    <div className="My_passwardBox">
                                        <div className="passward_inputBox">
                                            <input
                                                type="password"
                                                id="pw_ok"
                                                name="pw_ok"
                                                style={{imeMode: 'disabled'}}
                                                maxLength="15"
                                                autoComplete="off"
                                                value={pwOk}
                                                onChange={handlePwOkChange}
                                                required="true"
                                            />
                                        </div>
                                    </div>
                                    <em className="result_msg" id="result_pw_ok_msg"></em>
                                </div>

                                <div className="My_contents" id="nicknamebox">
                                    <div className="My_contents_innerleftBox" id="nicknamebox2">
                                        <em style={{paddingLeft: '60px'}} id="nicknamebox3">닉 네 임</em>
                                    </div>
                                    <div className="My_passwardBox">
                                        <div className="passward_inputBox">
                                            <input
                                                type="text"
                                                id="nickname"
                                                name="nickname"
                                                placeholder="최대 6글자"
                                                maxLength="6"
                                                autoComplete="off"
                                                value={nickname}
                                                onChange={handleNicknameChange}
                                                // onBlur={handleNicknameBlur}
                                                required="true"
                                            />
                                        </div>
                                    </div>
                                    <em className="result_msg" id="result_nickname_msg"></em>
                                </div>

                                <div className="My_contents" id="emailbox">
                                    <div className="My_contents_innerleftBox" id="emailbox2">
                                        <em style={{paddingLeft: '60px'}} id="emailbox3">이 메 일</em>
                                    </div>
                                    <div className="My_passwardBox">
                                        <div className="passward_inputBox">
                                            <input
                                                type="text"
                                                id="email"
                                                name="email"
                                                maxLength="50"
                                                autoComplete="off"
                                                value={email}
                                                onChange={handleEmailChange}
                                                required="true"
                                            />
                                        </div>
                                    </div>
                                    <em className="result_msg" id="result_email_msg"></em>
                                </div>

                            </div>

                            <div className="oknoBox_button">
                                <button className="okno_button" onClick={handleSubmit}>다음
                                    <div className="okno_button_img"><img src={icon_button} alt="icon"/></div>
                                </button>
                                <button className="okno_button" type="button" onClick={() => {
                                   window.location.href = '/login'
                                }} style={{backgroundColor: '#4e4e4e', marginLeft: '3px'}}>취소
                                    <div className="okno_button_img"><img src={icon_button} alt="icon"/></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {membershipCreated && (
                <MembershipCreateOk/>
            )}

            <SubFooter/>
        </div>
    );
}

export default MembershipCreate;

