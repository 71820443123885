import React, {useEffect, useState} from 'react';
import $ from "jquery";
import icon_page_first from "../images/servicecenter_img/Icon-page_first.png";
import icon_page_prev from "../images/servicecenter_img/Icon-page_prev.png";
import icon_page_next from "../images/servicecenter_img/Icon-page_next.png";
import icon_page_last from "../images/servicecenter_img/Icon-page_last.png";
import axios from "axios";
import baseUrl from "../BaseUrl";
import {useNavigate} from "react-router-dom";
import MobileVerification from "../mobileVerify/mobileVerification";
import ServiceCenterHeader from "./serviceCenterHeader";
import RightGamesWithoutLogin from "../game/rightGamesWithoutLogin";
import SubFooter from "../home/subFooter";

const renderDivision = (division) => {
    switch (division) {
        case 0:
            return '[설치/실행]';
        case 1:
            return '[계정]';
        case 2:
            return '[결제]';
        case 3:
            return '[이벤트]';
        case 4:
            return '[기타]';
        default:
            return '[알 수 없음]';
    }
};

const InquiryMainPage = () => {
    const navigate = useNavigate();


    return (
        <>
            <div className="subMaintitleBox">
                <div className="subMainGrayBox"/>
                <div className="subMaintitle">문의/제안하기</div>

                <div className="subMainDepth">고객센터 > 문의/제안하기</div>
            </div>

            <div style={{display: "flex", gap: '1.5px', marginTop: '40px'}}>
                <div className="inquiry_center_warp_box">
                    <div className="inquiry_contents_box_1">
                        <div className="inquiry_title_box">
                            <em>1:1문의 접수</em>
                        </div>
                        <div className="inquiry_title_box_2">
                            <em>도움말에서 원하시는 답을 찾지 못하셨다면</em><br/>
                            <em>1:1문의 접수로 문의/제안해주세요</em>
                        </div>
                        <button className="inquiry_button_box" onClick={() => navigate("/service_center/inquiry?page=receipt")}>
                            접수하기
                            <div className="inquiry_button_img">
                                <img src={require("../images/servicecenter_img/Icon-button.png")} alt="button icon"/>
                            </div>
                        </button>
                        <div className="inquiry_box_img">
                            <img src={require("../images/servicecenter_img/servicecenter_04.jpg")}
                                 alt="service center"/>
                        </div>
                    </div>
                </div>


                <div className="inquiry_center_warp_box">
                    <div className="inquiry_contents_box_1">
                        <div className="inquiry_title_box">
                            <em>접수내역</em>
                        </div>
                        <div className="inquiry_title_box_2">
                            <em>문의/제안하신 내용과 답변을</em><br/>
                            <em>상세히 확인하세요.</em>
                        </div>
                        <button className="inquiry_button_box" onClick={() => navigate("/service_center/inquiry?page=statement")}>
                            확인하기
                            <div className="inquiry_button_img">
                                <img src={require("../images/servicecenter_img/Icon-button.png")} alt="button icon"/>
                            </div>
                        </button>
                        <div className="inquiry_box_img">
                            <img src={require("../images/servicecenter_img/servicecenter_05.jpg")}
                                 alt="service center"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const ManToManReceipt = ({manToManStatement}) => {
    const [service, setService] = useState("4");
    const [contents, setContents] = useState('');
    const [title, setTitle] = useState('');
    const navigate = useNavigate();


    const handleServiceChange = (event) => {
        setService(event.target.value);
    };

    const handleContentsChange = (event) => {
        if (event.target.value.length <= 1000) {
            setContents(event.target.value.replace(/^\s+/, ''));
        }
    };


    const handleTitleChange = (event) => {
        let inputVal = event.target.value.replace(/^\s+/, '');
        const keyCode = event.which || event.keyCode;

        if (!(keyCode >= 37 && keyCode <= 40) && keyCode !== 8) {
            inputVal = inputVal.replace(/[<>''&]/gi, '');
        }

        setTitle(inputVal);
    };

    const inqueryRequest = async () => {
        if (!title && !contents){
            return alert("문의하실 제목과 상세 내용을 입력해 주세요.");
        }
        if (!title){
            return alert("문의하실 제목을 입력해 주세요.");
        }
        else if (!contents){
            return alert("문의하실 내용을 입력해 주세요.");
        }

        const data = {
            division: service,
            title: title,
            contents: contents
        };
        try {
            const response = await axios.post(
                baseUrl + 'service_center/inquiry_request/',
                data,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            )

            if (response.status === 201) {
                $('.man_category_color').removeClass('man_category_color');
                $('#statement_category').addClass('man_category_color');

                window.location.href = "/service_center/inquiry?page=statement";
            }
        } catch (e) {
            navigate('/login', {state: {redirect: "/service_center/inquiry?page=receipt"}});
        }
    }

    useEffect(() => {
        $('.subMaintitle').html('문의 접수');
        $('.subMainDepth').html('고객센터 > 문의/제안하기 > 1:1문의 접수');
    }, []);

    return (
        <>
            <div className="subMaintitleBox">
                <div className="subMainGrayBox"/>
                <div className="subMaintitle">문의 접수</div>

                <div className="subMainDepth">고객센터 > 문의/제안하기 > 1:1문의 접수</div>
            </div>

            <div className="man_contents_box">
                <div className="man_contents">
                    <div className="man_contents_inner_left_box">
                        <em>서비스 선택</em>
                    </div>

                    <div className="man_contents_inner_right_box">
                        <select
                            name="division"
                            className="man_drop_box_select"
                            value={service}
                            onChange={handleServiceChange}
                        >
                            <option value="4">전체</option>
                            <option value="0">설치/실행</option>
                            <option value="1">계정</option>
                            <option value="2">결제</option>
                            <option value="3">이벤트</option>
                            <option value="5">기타</option>
                        </select>
                    </div>
                </div>

                <div className="man_contents">
                    <div className="man_contents_inner_left_box">
                        <em>제 목</em>
                    </div>

                    <div className="man_password_box">
                        <div className="man_password_input_box">
                            <input
                                id="ManToManTitle"
                                type="text"
                                name="title"
                                maxLength="30"
                                autoComplete="off"
                                value={title}
                                onChange={handleTitleChange}
                                style={{width: '98%'}}
                            />
                        </div>
                    </div>

                </div>

                <div className="man_contents" style={{height: '250px'}}>
                    <div className="man_contents_inner_left_box">
                        <em>내 용</em>
                    </div>

                    <div className="man_txt_div">
                        <textarea
                            maxLength="1000"
                            className="man_text_area"
                            placeholder="최대 1000자까지 가능합니다."
                            value={contents}
                            onChange={handleContentsChange}>
                        </textarea>
                    </div>

                </div>
            </div>

            <div className="ok_no_box_button">
                <button className="ok_no_button" onClick={inqueryRequest}>
                    확인
                    <div className="man_ok_no_button_img">
                        <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                    </div>
                </button>
                <button
                    className="ok_no_button"
                    onClick={()=> navigate('/service_center/inquiry?page=main')}
                    style={{backgroundColor: '#4e4e4e', marginLeft: '5px'}}
                >
                    취소
                    <div className="man_ok_no_button_img">
                        <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                    </div>
                </button>
            </div>

        </>
    );
};


const ManToManStatement = () => {
    const [customerData, setCustomerData] = useState([]);
    const [customerDataCount, setCustomerDataCount] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [count, setCount] = useState([]);
    const navigate = useNavigate();

    function handlePrevPageChange() {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            customerService((pageNumber - 2) * 5);

            $('.qa_pagination_buttons').removeClass('qa_pagination_buttons');
            $(`#${pageNumber - 2}`).addClass('qa_pagination_buttons');

        }
    }

    function handleNextPageChange() {
        if (pageNumber < customerDataCount) {
            setPageNumber(pageNumber + 1);
            customerService(pageNumber * 5);

            $('.qa_pagination_buttons').removeClass('qa_pagination_buttons');
            $(`#${pageNumber}`).addClass('qa_pagination_buttons');
        }
    }

    const handlePageClick = (index) => {
        customerService(index *  5);
        setPageNumber(index);
        $('.qa_pagination_buttons').removeClass('qa_pagination_buttons');
        $(`#${index}`).addClass('qa_pagination_buttons');
    };

    function showContent(request_id) {
        navigate('/service_center/request_content?requestId='+request_id);
    }

    const customerService = async (offset) => {
        try {
            const response = await axios.get(baseUrl + 'service_center/customer_service/?limit=5&offset=' + offset, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (response.status === 200) {
                setCustomerData(response.data.results);
                const c = Math.ceil(response.data.count / 5)

                setCustomerDataCount(c)
                setCount(Array(c).fill(c));
            }
        } catch (e) {
            navigate('/login', {state: {redirect: "/service_center/inquiry?page=statement"}});
        }
    }

    useEffect(() => {
        customerService(0);
    }, []);

    return (
        <>
            <div className="subMaintitleBox">
                <div className="subMainGrayBox"/>
                <div className="subMaintitle">1:1문의 접수내역</div>

                <div className="subMainDepth">고객센터 > 문의/제안하기 > 접수내역</div>
            </div>

            <div className="billionaire_contents_box">
                <div className="billionaire_contents_title" style={{width: '156px'}}>
                    처리현황
                </div>
                <div className="billionaire_contents_title" style={{width: '566px'}}>
                    제목
                </div>
                <div className="billionaire_contents_title" style={{width: '110px'}}>
                    분류
                </div>
                <div className="billionaire_contents_title" style={{width: '158px'}}>
                    접수일자
                </div>
            </div>

            {customerData.map((data, index) => (
                <div className="billionaire_contents_box_2" onClick={event => showContent(data.cs_id)}>
                    <div className="billionaire_contents_title" style={{width: '156px'}}>
                        {data.answer === null ? '답변대기' : <a style={{color: '#15a3e1'}}>답변완료</a>}
                    </div>
                    <div className="billionaire_contents_title" style={{width: '566px'}}>
                        {data.title}&nbsp;
                    </div>
                    <div className="billionaire_contents_title" style={{width: '110px'}}>
                        {renderDivision(data.division)}
                    </div>
                    <div className="billionaire_contents_title" style={{width: '158px'}}>
                        {data.writeDate.split('T')[0]}
                    </div>
                </div>
            ))}

            <div className="notice_paging_box">

                <div className="service_paging_button">
                    <div onClick={handlePrevPageChange} style={{borderRight: '1px solid #dbdbdb'}}>
                        <img src={icon_page_first} alt=""/>
                    </div>
                    <div onClick={handlePrevPageChange} style={{borderRight: '1px solid #dbdbdb'}}>
                        <img src={icon_page_prev} alt=""/>
                    </div>
                </div>

                <div>
                    {count.map((value, index) => {
                        return (
                            <>
                                {index === 0 ?
                                    <button id={index} className="qa_pagination_buttons_2 qa_pagination_buttons" onClick={() => handlePageClick(index)}>
                                        {index + 1}
                                    </button>
                                    :
                                    <button id={index} className="qa_pagination_buttons_2" onClick={() => handlePageClick(index)}>
                                        {index + 1}
                                    </button>
                                }
                            </>
                        )

                    })}
                </div>


                <div className="service_paging_button" style={{borderLeft: '1px solid #dbdbdb'}}>
                    <div onClick={handleNextPageChange}>
                        <img src={icon_page_next} alt=""/>
                    </div>
                    <div onClick={handleNextPageChange} style={{borderLeft: '1px solid #dbdbdb'}}>
                        <img src={icon_page_last} alt=""/>
                    </div>
                </div>

            </div>
        </>
    );

};


const SelfUserRestriction = () => {
    const [numMonths, setNumMonths] = useState(0);
    const [restrictDateFrom, setRestrictDateFrom] = useState('');
    const [restrictedDateUpto, setRestrictedDateUpto] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [isUserRestricted, setIsUserRestricted] = useState(false);
    const [showUserRestriction, setShowUserRestriction] = useState(false);
    const navigate = useNavigate();
    const [mobileVerify, setMobileVerify] = useState(false);
    const [userId, setUserId] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const is_restricted = params.get('is_restricted');

    const handelRoundBtnCLick = (event, months) => {
        $('.user_restriction_round_buttons input').prop('checked', false);
        $(event.target).prop('checked', true);

        setNumMonths(months);
    };


    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}년 ${month}월 ${day}일`;
    }

    function openUserRestrictionPopup() {
        if (numMonths) {
            const currentDate = new Date();
            setRestrictDateFrom(formatDate(currentDate));

            const newDate = new Date(currentDate);
            newDate.setMonth(newDate.getMonth() + numMonths);
            localStorage.setItem('restricted_date_upto', formatDate(newDate));
            newDate.setDate(newDate.getDate() - 1);
            setRestrictedDateUpto(formatDate(newDate));

            setShowPopup(true);


        }

    }

    const handelUserRestriction = () => {
        setMobileVerify({
            page: 'selfUserRestriction',
            months: numMonths,
            user_id: userId
        })
    }

    const checkUserRestricted = async () => {
        try {
            const response = await axios.get(
                baseUrl + 'service_center/user_restriction/',
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            )

            const data = response.data
            if (data.is_restricted) {
                setIsUserRestricted(true);
                setRestrictDateFrom(data.restricted_date_from);
                setRestrictedDateUpto(data.restricted_date_upto);
                setUserId(data.user_id)
            } else {
                setUserId(data.user_id)
                setShowUserRestriction(true);
            }

        } catch (e) {
            navigate(`/login`, {state: {redirect: '/service_center/inquiry?page=selfUserRestriction'}})
        }
    };

    useEffect(() => {
        checkUserRestricted();

        if(is_restricted){
            localStorage.setItem('is_user_restricted', 1);
        }

    }, []);

    return (
        <>
            <div className="subMaintitleBox">
                <div className="subMainGrayBox"/>
                <div className="subMaintitle">자가 이용제한</div>

                <div className="subMainDepth">고객센터 > 문의/제안하기 > 자가 이용제한</div>
            </div>


            {showUserRestriction && (<>
                    <div className="man_contents_box">
                        <div className="man_contents" style={{height: "300px", lineHeight: 'unset'}}>
                            <div className="user_restriction_main_title">
                                웹보드 자가 이용제한
                            </div>

                            <div className="user_restriction_sub_title">
                                <div>
                            <span style={{fontWeight: 600}}>
                                게임 이용제한을 설정하시면 선택한 기간동안 웹보드 게임군 이용이 제한되며,<br/>
                                선택한 게임군을 제외한 에스엠게임즈의 서비스는 이용 가능합니다.
                            </span>

                                    <div style={{fontSize: '16px', marginTop: "20px"}}>
                                        ※ 이용제한 설정은 본인 확인 후 설정 가능합니다. 진행 버튼을 누르시면 본인 확인 단계로 넘어갑니다.
                                    </div>
                                </div>
                            </div>

                            <div className="user_restriction_buttons_div">
                                <div className="user_restriction_round_buttons">
                                    <input id="1_month" type="radio"
                                           onClick={event => handelRoundBtnCLick(event, 1)}></input>
                                    <span>1개월</span>
                                </div>

                                <div className="user_restriction_round_buttons">
                                    <input id="2_month" type="radio"
                                           onClick={event => handelRoundBtnCLick(event, 3)}></input>
                                    <span>3개월</span>
                                </div>

                                <div className="user_restriction_round_buttons">
                                    <input id="6_month" type="radio"
                                           onClick={event => handelRoundBtnCLick(event, 6)}></input>
                                    <span>6개월</span>
                                </div>

                                <div className="user_restriction_round_buttons">
                                    <input id="1_year" type="radio"
                                           onClick={event => handelRoundBtnCLick(event, 12)}></input>
                                    <span>1년</span>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="ok_no_box_button">
                        <button className="ok_no_button" onClick={openUserRestrictionPopup}>
                            확인
                            <div className="man_ok_no_button_img">
                                <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                            </div>
                        </button>
                        <button
                            className="ok_no_button"
                            onClick={() => navigate('/service_center/inquiry?page=main')}
                            style={{backgroundColor: '#4e4e4e', marginLeft: '5px'}}
                        >
                            취소
                            <div className="man_ok_no_button_img">
                                <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                            </div>
                        </button>
                    </div>
                </>

            )}

            {isUserRestricted && (<>
                    <div className="man_contents_box">
                        <div className="man_contents" style={{height: "320px", lineHeight: 'unset'}}>
                            <div className="user_restriction_main_title">
                                웹보드 자가 이용제한
                            </div>

                            <div className="user_restriction_sub_title">
                                <div>
                            <span style={{fontWeight: 600, textAlign: "center"}}>
                                <div style={{paddingBottom: '20px'}}>자가 이용제한 설정 상태입니다.</div>
                                회원님께서 자발적으로 설정하신 제한 기간 동안 웹보드 게임 이용이 불가능하며,<br/>
                                웹보드 게임을 제외한 에스엠게임즈의 서비스는 정상적으로 이용하실 수 있습니다
                            </span>

                                <div className="user_restriction_sub_title_2">
                                    ※ 자가 이용제한을 통해 설정하신 기간 중에는 해지가 불가능합니다.
                                </div>

                                    <div style={{textAlign: "center", paddingTop: '20px'}}>
                                    <span
                                        style={{fontWeight: 600}}>- 기간</span> : {restrictDateFrom} ~ {restrictedDateUpto}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="ok_no_box_button">
                        <button className="ok_no_button" onClick={event => navigate('/')}>
                            메인으로
                            <div className="man_ok_no_button_img"  style={{margin: '-18px 0 0 84px'}}>
                                <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                            </div>
                        </button>
                    </div>
                </>
            )}


            {showPopup && (<div className="user_restriction_popup">
                <div className="user_restriction_popup-content">
                    <div className="user_restrict_popup_div_1">
                        <span style={{width: '100%', textAlign: 'center'}}>자가 이용제한 안내</span>

                        <div style={{cursor: 'pointer', width: '5%'}} onClick={event => setShowPopup(false)}>
                            <img src={require("../images/mobile_img/Icon-close.png")}/>
                        </div>
                    </div>


                    <div className="user_restrict_popup_div_2">
                        회원님께서는 자가 이용제한을 설정하여 웹보드류 게임 이용이 제한됩니다.<br/>
                        다만 이 외에 에스엠게임즈 서비스를 이용하실 수 있습니다.<br/>
                    </div>

                    <div className="user_restrict_popup_div_3">
                        <div id="restricted_date">
                            <span style={{fontWeight: 600}}>- 기간</span> : {restrictDateFrom} ~ {restrictedDateUpto}
                        </div>
                    </div>

                    <div className="user_restrict_popup_div_3">
                        ※ 설정하신 기간동안은 해지가 불가능 합니다.
                    </div>

                    <div className="ok_no_box_button">
                        <button className="ok_no_button" onClick={handelUserRestriction}>
                            확인
                            <div className="man_ok_no_button_img">
                                <img src={require("../images/mypage_img/Icon-button.png")} alt="button icon"/>
                            </div>
                        </button>
                    </div>

                </div>

            </div>)}

            {mobileVerify && (<MobileVerification requestData={mobileVerify} randNum={11111}/>)}

        </>
    );
};


const Inquiry = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const page = params.get('page');


    const manToManReceipt = () => {
        setPageContent(<ManToManReceipt manToManStatement={manToManStatement}/>);
    };


    const manToManStatement = () => {
        setPageContent(<ManToManStatement/>);
    };

    const selfUserRestriction = () => {
        setPageContent(<SelfUserRestriction/>);
    };


    const [pageContent, setPageContent] = useState(<InquiryMainPage />);

    useEffect(() => {
        var token = localStorage.getItem('token');
        if (page === "receipt") {
            if(token)
                manToManReceipt();
            else
                navigate('/login', {state: {redirect: "/service_center/inquiry?page=receipt"}})

        } else if (page === "statement") {
            if(token)
                manToManStatement();
            else
                navigate('/login', {state: {redirect: "/service_center/inquiry?page=statement"}})

        } else if (page === "selfUserRestriction") {
            if(token)
                selfUserRestriction();
            else
                navigate('/login', {state: {redirect: "/service_center/inquiry?page=statement"}})
        } else {
            setPageContent(<InquiryMainPage />);
        }

    }, [page]);

    return (
        <>
            <ServiceCenterHeader category="inquiry" page={page}/>

            <div className="ServiceCenterWarpMain">
                <div className="ServiceGameCenterWarp" style={{height: 'auto'}}>
                    <div className="GameCenterwarp_inner">
                        {pageContent}
                    </div>

                    <RightGamesWithoutLogin/>
                </div>
            </div>

            <SubFooter/>
        </>
    );


};

export default Inquiry;
