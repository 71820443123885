import React, {useEffect, useState} from "react";
import MobileHeader from "./mHeader";
import {useNavigate} from "react-router-dom";
import $ from 'jquery';
import MobileFooter from "./mFooter";
import BaseUrl from "../BaseUrl";
import axios from "axios";
const MobileMembershipCreate = () => {
    const navigate = useNavigate();

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [pwOk, setPwOk] = useState('');
    const [nickname, setNickname] = useState('');
    const [email, setEmail] = useState('');

    const [isIdValid, setIsIdValid] = useState(false);
    const [isNicknameValid, setIsNicknameValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);

    const handleIdChange = (e) => {
        let inputVal = e.target.value.replace(/\s/g, '').replace(/[\p{Emoji}]/gu, '');
        let timer;

        // Remove special characters
        inputVal = inputVal.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=ㄱ-ㅎㅏ-ㅣ|가-힣]/gi, '');

        // Disallow starting with a number
        if (/^\d/.test(inputVal)) {
            inputVal = inputVal.slice(1);
        }

        if(inputVal.length > 11) {
            return;
        }

        setId(inputVal);

        $("#result_id_msg").html("");

        if (inputVal && inputVal.length < 5) {
            $("#idbox").height('100px');
            $("#idbox2").height('100px');
            $("#idbox3").css("line-height", "99px");
            $("#result_id_msg").html('<span id="id_msg" style="color:#cd642b;">최소 5글자 ~ 최대 11글자.</span>');
            return ;
        }

        clearTimeout(timer);

        if (inputVal) {
            timer = setTimeout(async () => {
                try {
                    await axios.get(BaseUrl + 'check_user_id/?user_id=' + inputVal);
                    $("#result_id_msg").html('사용 가능한 ID입니다.');
                    setIsIdValid(true);
                } catch (error) {
                    $("#result_id_msg").html('<span id="id_msg" style="color:#cd642b;">중복된 ID입니다</span>');
                    setIsIdValid(false);
                }
            }, 100); // 10 seconds
        }
    };


    const validatePassword = (password) => {
      const rules = [
        { regex: /.{6,15}/ },  // Password length between 6 and 15 characters
        { regex: /[A-Za-z]/ },    // At least one letter
        { regex: /[0-9]/ },    // At least one digit
        { regex: /[!@#$%^&*(),.?":{}|<>]/ }  // At least one special character
      ];

      for (let rule of rules) {
        if (!rule.regex.test(password)) {
          return false;
        }
      }

      return true;
    };


    const handlePwChange = (e) => {
        var inputVal = e.target.value.replace(/\s/g, '').replace(/[\p{Emoji}]/gu, '');
        inputVal = inputVal.replace(/[ \ㄱ-ㅎㅏ-ㅣ|가-힣 ]/gi, '');
        setPw(inputVal);

        if (inputVal){
            if (validatePassword(inputVal)) {
                $('#m_password').css('margin-top', '5vw');
                $("#result_pw_msg").css({
                    'line-height': 'unset',
                    'padding-top': '7.0313vw'
                });
                $("#result_pw_msg").html('');
            } else {
                $('#m_password').css('margin-top', '2vw');
                $("#result_pw_msg").css({
                    'line-height': '3vw',
                    'padding-top': '11.6vw'
                });
                $("#result_pw_msg").html('<span style="color:#cd642b;">비밀번호는 최소 6글자, 최대 15글자의<br/>영문 + 숫자 + 특수문자만 입력 가능합니다.</span>');
            }
        } else {
            $('#m_password').css('margin-top', '5vw');
            $("#result_pw_msg").css({
                'line-height': 'unset',
                'padding-top': '7.0313vw'
            });
            $("#result_pw_msg").html('');
        }

    };

    const handlePwOkChange = (e) => {
        const inputVal = e.target.value.replace(/\s/g, '').replace(/[\p{Emoji}]/gu, '');
        const result = inputVal.replace(/[ \ㄱ-ㅎㅏ-ㅣ|가-힣 ]/gi, '');
        setPwOk(result);

        if(result){
            if (pw === result) {
                $("#result_pw_ok_msg").html("<span style=\"color:#979797;\">일치 합니다.</span>");
            }
            else {
                $("#result_pw_ok_msg").html("<span style=\"color:#cd642b;\">비밀번호와 비밀번호 확인이 서로 다릅니다.</span>");
            }
        }
    };

    const handleNicknameChange = (e) => {
        const inputVal = e.target.value.replace(/\s/g, '').replace(/[\p{Emoji}]/gu, '');

        if(inputVal.length > 6) {
            return;
        }

        setNickname(inputVal);

        let timer;

        clearTimeout(timer);

        if (inputVal.length > 1){
            timer = setTimeout(async () => {
                try {
                    await axios.get(BaseUrl + 'check_nickname/?nickname=' + inputVal);

                    $("#result_nickname_msg").html('사용 가능한 닉네임입니다.');
                    setIsNicknameValid(true);

                } catch (error) {
                    $("#result_nickname_msg").html('<span id="id_msg" style="color:#cd642b;">이미 사용중인 닉네임입니다.</span>');
                    setIsNicknameValid(false);
                }
            }, 100);
        } else {
            if(inputVal.length === 1){
                $("#result_nickname_msg").html('<span id="id_msg" style="color:#cd642b;">닉네임은 최소 2글자~최대 6글자를 입력해 주세요.</span>');
            }
            else {
                $("#result_nickname_msg").html('');
            }
            setIsNicknameValid(false);
        }
    };

    const handleEmailChange = async (e) => {
        var email = e.target.value.replace(/\s/g, '').replace(/[\p{Emoji}]/gu, '');
        setEmail(email);

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const is_valid = emailPattern.test(e.target.value);

        if (is_valid) {
            try {
                await axios.get(BaseUrl + "check_email/?email=" + email);

                $("#result_email_msg").html('사용 가능한 이메일입니다.');
                setIsEmailValid(true);
            } catch (error) {
                $("#result_email_msg").html('<span id="id_msg" style="color:#cd642b;">잘못된 이메일 형식입니다.</span>');
                setIsEmailValid(false);
            }
        } else {
            setIsEmailValid(false);
            $("#result_email_msg").html('<span id="id_msg" style="color:#cd642b;">잘못된 이메일 형식입니다.</span>');
        }

    };

    const handleSubmit = async (e) => {
        if (id.length === 0) {
            return alert('아이디를 입력해 주세요.');
        }else if (id.length < 5) {
            return alert('아이디는 최소 5글자~최대 11글자를 입력해 주세요.');
        }
        else if (!isIdValid) {
            return alert('중복된 ID입니다.');
        }
        else if (!pw){
            return alert('비밀번호를 입력해 주세요.');
        }
        else if(!validatePassword(pw)){
            return alert('비밀번호는 최소 6글자, 최대 15글자의 영문 + 숫자 + 특수문자만\n입력 가능합니다.');
        }
        else if (!pwOk){
            return alert('비밀번호 확인을 입력해 주세요.');
        }
        else if (pw !== pwOk) {
            return alert('비밀번호와 비밀번호 확인이 서로 다릅니다.');
        }
        else if(!nickname) {
            return alert('닉네임을 입력해 주세요.');
        }
        else if(nickname.length < 2) {
            return alert('닉네임은 최소 2글자~최대 6글자를 입력해 주세요.');
        }
        else if (!isNicknameValid) {
            return alert('이미 사용중인 닉네임입니다.');
        }
        else if (!email){
            return alert('이메일 주소를 입력해 주세요.');
        }
        else if (!isEmailValid) {
            return alert('잘못된 이메일 형식입니다.');
        }

        var data = {
            user_id: id,
            password: pw,
            conform_password: pwOk,
            nickname: nickname,
            email: email,
            token: token
        };


        try {
            const response = await axios.post(BaseUrl + "membership_create/", data);
            localStorage.setItem('token', response.data.access);
            navigate('/mobile/membership_create_ok');
        } catch (error) {
            alert('invalid token');
            navigate('/mobile/membership');
        }
    };

    useEffect(() => {

        window.history.pushState(null, "", window.location.href);

        window.onpopstate = (event) => {
          window.history.pushState(null, "", window.location.href);
        };

    }, []);


    useEffect(() => {
        $('body').css({
            'transform': 'unset',
            'min-width': '320px',
            'zoom': 'unset'
        });

    }, []);
    return (
        <>
            <MobileHeader/>

            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="m_top_my_section">
                    <span style={{fontSize: '5.3125vw', fontWeight: "bold"}}>정보입력</span>
                </div>

                <div className="m_member_contents">
                    <div className="m_member_title_box">
                        정보입력
                    </div>

                    <div className="my_contents_box">
                        <div className="my_contents">
                            <div className="m_my_contents_inner_left_box">
                                <em className="my_contents_inner_left_txt">아 이 디</em>
                            </div>

                            <div className="m_my_password_box">
                                <div className="m_my_password_input_box">
                                    <input
                                        type="text"
                                        placeholder="최소 5글자 ~ 최대 11글자"
                                        maxLength="11"
                                        autoComplete="off"
                                        value={id}
                                        required="true"
                                        onChange={handleIdChange}
                                        // onBlur={handleIdChangeBlur}
                                    />

                                </div>
                            </div>

                            <em className="m_result_msg" id="result_id_msg"></em>
                        </div>


                        <div className="my_contents">
                            <div className="m_my_contents_inner_left_box">
                                <em className="my_contents_inner_left_txt">비밀번호</em>
                            </div>

                            <div className="m_my_password_box" id="m_password">
                                <div className="m_my_password_input_box">
                                    <input
                                        type="password"
                                        placeholder="최소 6글자 ~ 최대 15글자"
                                        maxLength="15"
                                        autoComplete="off"
                                        value={pw}
                                        onChange={handlePwChange}
                                        // onBlur={handlePwBlur}
                                        required="true"
                                    />

                                </div>
                            </div>
                            <em className="m_result_msg" id="result_pw_msg"></em>
                        </div>


                        <div className="my_contents">
                        <div className="m_my_contents_inner_left_box">
                                <em className="my_contents_inner_left_txt">비밀번호 확인</em>
                            </div>

                            <div className="m_my_password_box">
                                <div className="m_my_password_input_box">
                                    <input
                                        type="password"
                                        maxLength="15"
                                        autoComplete="off"
                                        value={pwOk}
                                        onChange={handlePwOkChange}
                                        required="true"
                                    />

                                </div>
                            </div>

                            <em className="m_result_msg" id="result_pw_ok_msg"></em>
                        </div>


                        <div className="my_contents">
                            <div className="m_my_contents_inner_left_box">
                                <em className="my_contents_inner_left_txt">닉 네 임</em>
                            </div>

                            <div className="m_my_password_box">
                                <div className="m_my_password_input_box">
                                    <input
                                        type="text"
                                        placeholder="최대 6글자"
                                        maxLength="11"
                                        autoComplete="off"
                                        value={nickname}
                                        onChange={handleNicknameChange}
                                        // onBlur={handleNicknameBlur}
                                        required="true"
                                    />

                                </div>
                            </div>

                            <em className="m_result_msg" id="result_nickname_msg"></em>
                        </div>


                        <div className="my_contents">
                            <div className="m_my_contents_inner_left_box">
                                <em className="my_contents_inner_left_txt">이 메 일</em>
                            </div>

                            <div className="m_my_password_box">
                                <div className="m_my_password_input_box">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required="true"
                                    />

                                </div>
                            </div>

                            <a className="m_result_msg" id="result_email_msg"></a>
                        </div>


                    </div>

                    <div className="m_ok_btn_box" style={{display: "flex", justifyContent: "center"}}>
                        <button className="m_ok_no_button" id="find_id_next_button" onClick={handleSubmit}>
                            동의
                            <div className="m_ok_no_button_img"/>
                        </button>

                        <button className="m_ok_no_button" id="find_id_cancel_button" type="button"
                                onClick={() => navigate('/mobile/login')}
                                style={{backgroundColor: '#4e4e4e', marginLeft: '0.4688vw'}}>취소
                            <div className="m_ok_no_button_img"/>
                        </button>
                    </div>

                </div>


            </div>

            <MobileFooter/>
        </>
    )
}

export default MobileMembershipCreate