import React, {useEffect, useState} from 'react';
import '../css/membership.css';
import close_btn from "../images/Icon-closebutton.png";
import icon_mobile from "../images/membership_img/Icon-mobile.jpg";
import icon_button from "../images/mypage_img/Icon-button.png";

import {useLocation, useNavigate} from 'react-router-dom';
import SubHeader from "./authSubHeader";
import axios from "axios";
import BaseUrl from "../BaseUrl";
import MobileVerification from "../mobileVerify/mobileVerification";
import $ from 'jquery';
import baseUrl from "../BaseUrl";
import SubFooter from "../home/subFooter";
import ScrollToTop from "../scrollToTop";

function ShowPwPopup({handleClose, setMobileVerify, changePwToken}) {
    const navigate = useNavigate();

    const [inputId, setInputId] = useState('');
    const [isIdValid, setIsIdValid] = useState(false);
    const [pw, setPw] = useState('');
    const [pwOk, setPwOk] = useState('');

    const searchId = (e) => {
        let inputVal = e.target.value;
        let timer;

        // Remove special characters
        inputVal = inputVal.replace(/[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=ㄱ-ㅎㅏ-ㅣ|가-힣]/gi, '');

        // Disallow starting with a number
        if (/^\d/.test(inputVal)) {
            inputVal = inputVal.slice(1);
        }

        setInputId(inputVal);

        // Clear previous timer to avoid multiple API calls
        $("#change_pw_content_2").html("");
        clearTimeout(timer);

        // Set timer to make API call after 10 seconds
        if (inputVal) {
            timer = setTimeout(async () => {
                try {
                    const response = await axios.get(baseUrl + 'search_user_id/?user_id='+ inputVal);
                    if (response.status === 200) {
                        setIsIdValid(true);
                        $('#change_pw_title').css('display', 'none');
                        $('#change_pw_content').css('display', 'none');
                        $('#change_pw_content_2').html('<span style="color: #979797;">존재하는 아이디입니다.</span>');
                    }

                } catch (error) {
                    if($('#Find_id').val()) {
                        setIsIdValid(false);
                        $('#change_pw_title').css('display', 'none');
                        $('#change_pw_content').css('display', 'none');
                        $('#change_pw_content_2').html('<span style="color: #cd642b;">존재하지 않는 아이디입니다.</span>');
                    }
                }

            }, 100); // 10 seconds
        } else {
            setIsIdValid(false);
            $('#change_pw_content_2').html('');
            $('#change_pw_title').css('display', 'block');
            $('#change_pw_content').css('display', 'block');
        }
    };


    const searchUser = async (e) => {
        if (!inputId){
            return alert('아이디를 입력해주세요.');
        }

        if (!isIdValid){
            return alert('존재하지 않는 아이디입니다.');
        }

        try {
            const response = await axios.get(baseUrl + 'search_user_id/?user_id='+ inputId);
            if (response.status === 200) {
                setMobileVerify({page: 'changePassword', user_id: inputId});
                handleClose();
            }

        } catch (error) {
            setInputId('');
            $('#change_pw_title').css('display', 'none');
            $('#change_pw_content').css('display', 'none');
            $('#change_pw_content_2').html('<span style="color: #cd642b;">존재하지 않는 아이디입니다.</span>');
        }

    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchUser();
        }
    };

    const validatePassword = (password) => {
      const rules = [
        { regex: /.{6,15}/ },  // Password length between 6 and 15 characters
        { regex: /[A-Za-z]/ },    // At least one letter
        { regex: /[0-9]/ },    // At least one digit
        { regex: /[!@#$%^&*(),.?":{}|<>]/ }  // At least one special character
      ];

      for (let rule of rules) {
        if (!rule.regex.test(password)) {
          return false;
        }
      }

      return true;
    };


    const handlePwChange = (e) => {
        const inputVal = e.target.value;
        const result = inputVal.replace(/[\ㄱ-ㅎㅏ-ㅣ|가-힣]/gi, '');
        setPw(result);
    };


    const handlePwOkChange = (e) => {
        const inputVal = e.target.value;
        const result = inputVal.replace(/[\ㄱ-ㅎㅏ-ㅣ|가-힣]/gi, '');
        setPwOk(result);
    };

    const changePassword = async () => {
        if (!pw) {
            return alert('비밀번호를 입력해 주세요.');
        }
        else if(!validatePassword(pw)){
            return alert('비밀번호는 최소 6글자, 최대 15글자의 영문 + 숫자 + 특수문자만 \n입력 가능합니다.');
        }
        else if(!pwOk){
            return alert('비밀번호 확인을 입력해 주세요.');
        }
        else if (pw !== pwOk) {
            return alert('비밀번호와 비밀번호 확인이 서로 다릅니다.');
        }

        try {
            const data = {
                'api_token': changePwToken,
                'password': pw,
                'conform_password': pwOk
            };

            const response = await axios.post(baseUrl + 'mobile_verification/change_password/', data);

            if (response.status === 200) {
                alert('비밀번호가 변경되었습니다.');
                navigate('/login');
                window.location.reload();
            }

        } catch (error) {
            if (error.response.status === 400) {
                alert(error.response.data.message);
            }
            navigate('/login');
            window.location.reload();
        }
    };

    useEffect(() => {
        if (changePwToken){
            $('#FindInnerpw').css('display', 'none');
            $('#FindInnerpw2').css('display', '');
            $('#Findpw_nextbutton').css('display', 'none');
            $('#Findpw_changebutton').css('display', '');
        }

    }, [changePwToken]);

    return (
        <div className="Findid_popup" id="pwpopup" style={{zIndex: 2}}>

            <div className="Findid_titleBox">
                <em>비밀번호 찾기</em>
                <button onClick={handleClose} type="button" style={{position: 'absolute', margin: '6px 0 0 125px'}}>
                    <img src={close_btn} alt="Close"/>
                </button>
            </div>

            <div className="Findid_Box">
                <div className="Findid_Inner pw" id="FindInnerpw">
                    <div className="Findpw_passwardBox">
                        <div className="Findpw_inputBox">
                            <input
                                type="text"
                                id="Find_id"
                                name="Find_id"
                                placeholder="아이디를 입력해주세요."
                                maxLength="11"
                                style={{imeMode: 'inactive'}}
                                autoComplete="off"
                                value={inputId}
                                onChange={event => searchId(event)}
                                onKeyDown={handleKeyDown}
                            />
                        </div>
                    </div>
                    <div className="Findid_mobileimg">
                        <img src={icon_mobile} alt="Mobile"/>
                    </div>
                    <em id="change_pw_title" className="Findid_Title">휴대폰 인증<br/></em>
                    <em id="change_pw_content" className="Findid_Contents">휴대폰 인증 후 비밀번호를 찾으실 수 있습니다.</em>
                    <em id="change_pw_content_2" className="Findid_Contents"
                        style={{position: 'absolute', margin: '13px 20px 0px 50px'}}>
                    </em>
                </div>

                <div className="Findid_Inner pw" id="FindInnerpw2" style={{display: 'none'}}>
                    <em className="Findid_Title" style={{margin: '-21px 0 0 -40px'}}>비밀번호 변경<br/></em>
                    <div className="Findpw_passwardBox">
                        <div className="Findpw_inputBox">
                            <input
                                type="password"
                                id="password"
                                placeholder="변경할 비밀번호를 입력해주세요."
                                maxLength="15"
                                autoComplete="off"
                                value={pw}
                                onChange={handlePwChange}
                                required="true"
                            />
                        </div>
                    </div>
                    <div className="Findpw_passwardBox">
                        <div className="Findpw_inputBox">
                            <input
                                type="password"
                                id="conform_password"
                                placeholder="한번더 입력해주세요."
                                maxLength="15"
                                autoComplete="off"
                                value={pwOk}
                                onChange={handlePwOkChange}
                                required="true"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="oknoBox_button Findid">
                <button className="okno_button" id="Findpw_changebutton" type="button" onClick={changePassword}
                        style={{display: 'none'}}>변경
                    <div className="okno_button_img"><img src={icon_button} alt="Change"/></div>
                </button>
                <button className="okno_button" id="Findpw_nextbutton" type="button" onClick={searchUser}>다음
                    <div className="okno_button_img"><img src={icon_button} alt="Next"/></div>
                </button>
                <button className="okno_button" type="button" onClick={handleClose}
                        style={{backgroundColor: '#4e4e4e', marginLeft: '3px'}}>취소
                    <div className="okno_button_img"><img src={icon_button} alt="Cancel"/></div>
                </button>
            </div>

        </div>
    );
}


function Login(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const params = new URLSearchParams(window.location.search);
    var page = params.get('page');
    const userIDs = params.get('userIDs');
    const changePwToken = params.get('changePwToken');


    try {
        page = location.state.page;
    } catch (e) {

    }

    var redirect;

    try {
        redirect = location.state.redirect;
    } catch (e) {

    }


    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [showIdPopup, setShowIdPopup] = useState(false);
    const [showPwPopup, setShowPwPopup] = useState(false);

    const [mobileVerify, setMobileVerify] = useState(false);
    const [randNum, setRandNum] = useState(1);

    const handleIdChange = (e) => setId(e.target.value);
    const handlePwChange = (e) => setPw(e.target.value);

    const handleFindId = () => {
        setShowIdPopup(true);
    };

    const handleMobileVerify = (page) => {
        setMobileVerify({page: page});
        setRandNum(randNum + 1);
        setShowIdPopup(false);
    }

    const handleFindPw = () => {
        setShowPwPopup(true);
    };

    const handleSubmit = async (e) => {
        localStorage.clear();
        sessionStorage.clear();

        e.preventDefault();

        const formData = new FormData(e.target);
        const data = {};

        formData.forEach((value, key) => {
            data[key] = value;
        });

        var inputs = document.querySelectorAll('.input_form input');
        inputs.forEach(function (input) {
            input.value = '';
        });


        try {
            const response = await axios.post(BaseUrl + 'login/', data);

            const responseData = response.data;

            if (response.status === 200) {
                const now = new Date();
                now.setTime(now.getTime() + 12 * 60 * 60 * 1000);
                document.cookie = `token=${responseData.access}; path=/; Secure; SameSite=Strict`;
                document.cookie = `tokenTime=time; expires=${now.toUTCString()}; path=/; Secure; SameSite=Strict`;

                localStorage.setItem('token', responseData.access);
                localStorage.setItem('is_user_restricted', responseData.is_user_restricted);
                localStorage.setItem('restricted_date_upto', responseData.restricted_date_upto);
                localStorage.setItem('gender', responseData.gender);
                localStorage.setItem('user_verified', responseData.user_verified);
                localStorage.setItem('user_id', responseData.user_id);

                if (redirect) {
                    navigate(redirect);
                } else {
                    navigate('/');
                }

            }
            else {
                alert("아이디와 비밀번호를 확인해주세요.");
            }


        } catch (error) {
            alert("아이디와 비밀번호를 확인해주세요.");
        }

    };

    const handleClose = () => {
        setShowIdPopup(false);
        setShowPwPopup(false);
    };

    useEffect(() => {
        if (page === "serviceCenterFindID"){
            $('#find_id_text_1').text('휴대폰 인증');
            $('#find_id_text_2').text('휴대폰 인증 후 아이디를 찾으실 수 있습니다.');
        }
        else if (page === 'findID') {
            if (userIDs)
                $('#find_id_text_2').text(userIDs);
            else
                $('#find_id_text_2').text('가입된 아이디가 없습니다.');

            $('#find_id_ok_btn').css('display', 'none');
            $('#find_id_text_1').text('아이디 확인 결과');

        } else {
            $('#find_id_text_1').text('휴대폰 인증');
            $('#find_id_text_2').text('휴대폰 인증 후 아이디를 찾으실 수 있습니다.');
        }
    }, [showIdPopup]);


    useEffect(() => {
        if (page === 'findID' || page === 'serviceCenterFindID') {
            setShowIdPopup(true);
        } else if (page === 'changePassword') {
            setShowPwPopup(true);
        } else if (page === 'verificationFailed'){
            alert('Mobile Verification Failed')
        } else if (page === 'invalidUserId'){
            alert('입력하신 정보와 모두 일치하는 아이디가 없습니다.')
        }
    }, [page]);

    return (
        <>
            <ScrollToTop />

            <SubHeader/>

            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                {/* Header */}
                <div className="TopMysection TopMysectionBg">
                    <div className="TopMysection_inner">
                        <div className="TopMysection_title">로그인</div>
                    </div>
                </div>

                {/* Contents */}
                <div id="contents">
                    <div className="Centerwarp">
                        {/* ID Popup */}
                        {showIdPopup && (
                            <div className="Findid_popup" id="idpopup" style={{display: 'block', zIndex: 2}}>
                                <div className="Findid_titleBox">
                                    <em>아이디 찾기</em>
                                    <button onClick={handleClose}
                                            style={{position: 'absolute', margin: '6px 0 0 134px'}}>
                                        <img src={close_btn} alt="Close"/>
                                    </button>
                                </div>
                                <div className="Findid_Box">
                                    <div className="Findid_Inner">
                                        <div className="Findid_mobileimg">
                                            <img src={icon_mobile} alt="Mobile"/>
                                        </div>
                                        <div className="Findid_Title" id="find_id_text_1"></div>
                                        <div className="Findid_Contents" id="find_id_text_2"></div>
                                    </div>
                                </div>
                                <div className="oknoBox_button Findid">
                                    <button id="find_id_ok_btn" className="okno_button" onClick={() => handleMobileVerify('findID')}>
                                        다음
                                        <div className="okno_button_img"><img src={icon_button} alt="Next"/></div>
                                    </button>
                                    <button className="okno_button"
                                            onClick={handleClose}
                                            style={{backgroundColor: '#4e4e4e', marginLeft: '3px'}}>
                                        {page === "findID"? '확인': '취소'}
                                        <div className="okno_button_img"><img src={icon_button} alt="Cancel"/></div>
                                    </button>
                                </div>
                            </div>
                        )}

                        {/* Password Popup */}
                        {showPwPopup && (<ShowPwPopup handleClose={handleClose} setMobileVerify={setMobileVerify} changePwToken={changePwToken}/>)}


                        {/* Membership Box */}
                        <div className="membership_Box" style={{backgroundColor: '#fff'}}>
                            <div className="memberd">
                                <div className="login login_common_ly" id="login_common_ly">
                                    <h2 className="blind">로그인</h2>
                                    <div className="login_setd lg_iptxt">
                                        {/* Social Media Login */}
                                    </div>
                                    <form method="post" name="login_enterform" onSubmit={handleSubmit}>
                                        <div className="login_form idpw_area">
                                            <div id="login_box">
                                                <div className="input_form">
                                                    <div id="inputId" className="login_form_itemp input_id">
                                                        <input
                                                            type="text"
                                                            name="user_id"
                                                            id="id"
                                                            title="아이디"
                                                            className="txt_inp"
                                                            placeholder="아이디"
                                                            maxLength="11"
                                                            value={id}
                                                            onChange={handleIdChange}
                                                            autoComplete="off"
                                                            required={true}
                                                            // onInvalid={(e) => e.target.setCustomValidity('Testing')} // Custom message for invalid input
                                                        />
                                                    </div>
                                                    <div id="inputPassword" className="login_form_itemp input_pw">
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            id="pw"
                                                            title="비밀번호"
                                                            className="txt_inp"
                                                            placeholder="비밀번호"
                                                            maxLength="15"
                                                            value={pw}
                                                            onChange={handlePwChange}
                                                            autoComplete="off"
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <button id="btnLogin" type="submit" title="로그인"
                                                    className="login_btnd is_disabled lg_input_btn"
                                                    style={{cursor: 'pointer'}}>
                                                <span className="login_btn_txt">로그인</span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="login_help lg_member_con">
                                    <span className="mem_id_search">
                                      <button className="login_help_link" onClick={handleFindId}>아이디 찾기</button>
                                    </span>
                                    <span className="login_help_bar"></span>

                                    <span className="mem_pw_search">
                                      <button className="login_help_link" onClick={handleFindPw}>비밀번호 찾기</button>
                                    </span>
                                    <span className="login_help_bar"></span>

                                    <span className="mem_join">
                                      <button className="login_help_link" onClick={() => navigate('/membership')}>회원가입</button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {mobileVerify && (<MobileVerification requestData={mobileVerify} randNum={randNum}/>)}
            <SubFooter/>

        </>
    );
}

export default Login;
