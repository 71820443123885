import React, {useEffect, useState} from 'react';
import '../css/membership.css';
import icon_uncheck from "../images/membership_img/Icon-uncheck.png";
import icon_check from "../images/membership_img/Icon-check.png";
import icon_down from "../images/membership_img/Icon-down.png";
import icon_button from "../images/mypage_img/Icon-button.png";
import icon_mobile from "../images/membership_img/Icon-mobile.jpg";
import {useNavigate} from "react-router-dom";
import SubHeader from "./authSubHeader";
import TermOfUse from "./termOfUsePolicy";
import PrivacyPolicy from "./privacyPolicy";
import MobileVerification from "../mobileVerify/mobileVerification";
import SubFooter from "../home/subFooter";

const MembershipVerify = () => {
    const navigate = useNavigate();
    const params = new URLSearchParams(window.location.search);
    const status = params.get('status');
    const token = params.get('token');

    const [agreeAll, setAgreeAll] = useState(false);
    const [agree1, setAgree1] = useState(false);
    const [agree2, setAgree2] = useState(false);
    const [agree3, setAgree3] = useState(false);
    const [showAgree1, setShowAgree1] = useState(false);
    const [showAgree2, setShowAgree2] = useState(false);
    const [mobileVerify, setMobileVerify] = useState(false);
    const [randNum, setRandNum] = useState(1);

    const handleAgree = (status) => {
        if (status === 'all') {
            setAgreeAll(!agreeAll);
            setAgree1(!agreeAll);
            setAgree2(!agreeAll);
            setAgree3(!agreeAll);
        } else if (status === 1) {
            setAgree1(!agree1);

            if (!agree1 && agree2 && agree3) {
                setAgreeAll(true);
            } else {
                setAgreeAll(false);
            }

        } else if (status === 2) {
            setAgree2(!agree2);

            if (agree1 && !agree2 && agree3) {
                setAgreeAll(true);
            } else {
                setAgreeAll(false);
            }

        } else if (status === 3) {
            setAgree3(!agree3);

            if (agree1 && agree2 && !agree3) {
                setAgreeAll(true);
            } else {
                setAgreeAll(false);
            }
        }

        if (agree1 && agree2 && agree3) {
            setAgreeAll(!agreeAll);
        }
    };


    const handleCheckOk = (e) => {
        if (!agree1) {
            e.preventDefault();
            alert("SM게임즈 이용약관에 동의해주세요.");
            return;
        }
        if (!agree2) {
            e.preventDefault();
            alert("SM게임즈 개인정보처리방침에 동의해주세요.");
            return;
        }
        if (!agree3) {
            e.preventDefault();
            alert("휴대폰인증에 동의해주세요.");
            return;
        }
        setMobileVerify(true);
        setRandNum(randNum + 1);
    };

    const handleShowAgree = (status) => {
        if (status === 1) {
            setShowAgree1(!showAgree1);
        } else if (status === 2) {
            setShowAgree2(!showAgree2);
        }
    };

    useEffect(() => {
        if(token) {
            localStorage.setItem('token', token);
            navigate('/');
        }

        if (status){
            alert('입력하신 정보와 모두 일치하는 아이디가 없습니다.');
        }

    }, []);

    return (
        <>
            <SubHeader/>

            <div className="warp" style={{backgroundColor: '#f5f5f5'}}>
                <div className="TopMysection">
                    <div className="TopMysection_inner">
                        <div className="TopMysection_title">회원가입</div>
                    </div>
                </div>

                {/* Membership Agreement */}
                <div id="contents">
                    <div className="Centerwarp">
                        <div className="Centerwarp_inner">
                            {/* Agreement */}
                            <div className="subMaintitleBox">
                                <div className="subMainGrayBox"></div>
                                <div className="subMaintitle"><em>약관동의</em></div>
                            </div>
                            <div className="membership_Box">
                                <div className="membership_TitleBox">
                                    <div className="membership_Title">
                                        <div className="membership_uncheckimg">
                                            <img id="agreeAll" src={agreeAll ? icon_check : icon_uncheck}
                                                 onClick={() => handleAgree('all')}/>
                                        </div>
                                        <em style={{paddingLeft: '29px'}}>SM게임즈 이용약관 및 개인정보 취급방침에 모두 동의합니다.</em>
                                    </div>
                                </div>
                                {/* Agreement Contents */}
                                <div className="membership_contents">
                                    {/* SM게임즈 이용약관 동의 */}
                                    <div className="membership_checkimg">
                                        <img id="agree1" src={agree1 ? icon_check : icon_uncheck}
                                             onClick={() => handleAgree(1)}/>
                                    </div>
                                    <div style={{display: 'inline-block', paddingLeft: '64px', paddingBottom: '33px'}}>
                                        <em>SM게임즈 이용약관에 동의</em>
                                    </div>
                                    <div style={{position: 'absolute', display: 'inline-block', margin: '0 0 0 252px'}}
                                         onClick={() => handleShowAgree(1)}>
                                        <em>약관보기</em>
                                        <img src={icon_down} style={{paddingTop: '7px'}}/>
                                    </div>
                                </div>
                                {/* Show Agreement 1 */}
                                <div id="showagree1" className="agreement_Box"
                                     style={{display: showAgree1 ? 'block' : 'none', paddingBottom: '21px'}}>
                                    <div className="agreement_contents">
                                        <div className="agreement_contents_Text">
                                            {/* Membership term of use */}

                                            <TermOfUse/>

                                        </div>
                                    </div>
                                </div>
                                {/* Agreement Contents */}
                                <div className="membership_contents">
                                    {/* SM게임즈 개인정보처리방침 동의 */}
                                    <div style={{display: 'block', paddingLeft: '64px'}}>
                                        <div className="membership_checkimg" style={{marginLeft: '-34px'}}>
                                            <img id="agree2" src={agree2 ? icon_check : icon_uncheck}
                                                 onClick={() => handleAgree(2)}/>
                                        </div>
                                        <em>SM게임즈 개인정보처리방침에 동의</em>
                                        <div style={{
                                            position: 'absolute',
                                            display: 'inline-block',
                                            margin: '0 0 0 174px'
                                        }} onClick={() => handleShowAgree(2)}>
                                            <em>처리방침보기</em>
                                            <img src={icon_down} style={{paddingTop: '7px'}}/>
                                        </div>
                                    </div>
                                </div>
                                {/* Show Agreement 2 */}
                                <div id="showagree2" className="agreement_Box"
                                     style={{display: showAgree2 ? 'block' : 'none'}}>
                                    <div className="agreement_contents">
                                        <div className="agreement_contents_Text">
                                            {/* Membership privacy policy */}

                                            <PrivacyPolicy/>

                                        </div>
                                    </div>
                                </div>
                                {/* Agreement Checkbox */}
                                <div className="membership_TitleBox"
                                     style={{borderBottom: 'none', borderTop: '1px solid #d3d3d3'}}>
                                    <div className="membership_Title">
                                        <div className="membership_uncheckimg">
                                            <img id="agree3" src={agree3 ? icon_check : icon_uncheck}
                                                 onClick={() => handleAgree(3)}/>
                                        </div>
                                        <em style={{paddingLeft: '29px'}}>휴대폰 인증 <em
                                            style={{fontSize: '14px', fontWeight: 'normal', color: '#7b7b7b'}}> 본인확인을 위한
                                            인증절차 입니다.<img src={icon_mobile} style={{margin: '-11.5px 0 0 189px'}}/></em></em>
                                    </div>
                                </div>
                            </div>

                            <div className="oknoBox_button">
                                <button className="okno_button" onClick={handleCheckOk}>동의
                                    <div className="okno_button_img"><img src={icon_button}/></div>
                                </button>
                                <button className="okno_button" type="button" onClick={() => navigate('/login')}
                                        style={{backgroundColor: '#4e4e4e', marginLeft: '3px'}}>취소
                                    <div className="okno_button_img"><img src={icon_button}/></div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {mobileVerify && (<MobileVerification requestData={{page: 'membershipVerify', user_id: localStorage.getItem('user_id')}} />)}

            <SubFooter/>

        </>

    );
};

export default MembershipVerify;
