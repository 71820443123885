import React, {useEffect, useState} from 'react';
import icon_plus from "../images/Icon-plus.png";
import axios from "axios";
import baseUrl from "../BaseUrl";
import $ from "jquery";

const QAPage = ({gameName, searchTxt, searchDiv}) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [faqItems, setFaqItems] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [qaContent, setQAContent] = useState(false);
    const [tabGameNames, setTabGameNames] = useState([]);
    const [faqGameNo2, setFaqGameNo2] = useState('');
    const [gameTab, setGameTab] = useState(0);
    let faqGameNo;

    async function qaData(offset, faqGameNum) {
        var subUrl;
        if (gameName === "other"){
            if(searchDiv){
                subUrl = 'service_center/game_qa/?search='+searchTxt+'&faq_div='+searchDiv+'&limit=5&offset='+offset;
            } else {
                subUrl = 'service_center/game_qa/?search='+searchTxt+'&limit=5&offset='+offset;
            }
        } else {
            subUrl = 'service_center/game_qa/?faq_game='+faqGameNum+'&ordering=-faq_no&limit=5&offset=' + offset;
        }

        try {
            const response = await axios.get(baseUrl + subUrl);
            if (response.status === 200) {
                setFaqItems(response.data.results);
                setNumberOfPages(Math.ceil(response.data.count / 5));
            }
        } catch (e) {

        }
    }

    const showQAContent = (index) => {
        setQAContent(faqItems[index]);
        $('.GameCenterwarp_inner').css({
            'height': 'auto',
            'padding-bottom': '100px'
        });
        $('html, body').animate({
            scrollTop: $('#quit_box_id').offset().top
        }, 0);

    };


    function gameNameSet(faq_game) {
        switch (faq_game) {
            case 1:
                faq_game = "SM 신맞고";
                break;
            case 2:
                faq_game = "SM 7포커";
                break;
            case 3:
                faq_game = "SM 바둑이";
                break;
            case 4:
                faq_game = "홈페이지";
                break;
        }

        return faq_game;
    }

    function changeQAPage(index) {
        var offset = index * 5;
        setPageIndex(index);

        if(gameName === 'other'){
            qaData(offset, 0);
        } else {
            qaData(offset, faqGameNo2);
        }
    }


    function changeGameQAPage(e, name) {
        if(name === '신맞고') {
            faqGameNo = 1;
        } else if (name === 'SM 7포커'){
            faqGameNo = 2;
        } else {
            faqGameNo = 3;
        }

        setFaqGameNo2(faqGameNo);
        setPageIndex(0);
        qaData(0, faqGameNo);

        $('.service_tab_links_a').removeClass('active');
        $(e.currentTarget).addClass('active');
    }

    useEffect(() => {
        if (gameName === "other"){
            console.log(gameName);
        }
        else if(gameName === 'sm_dualgo') {
            setTabGameNames(['신맞고']);
            faqGameNo = 1;
            setGameTab(0);
        }
        else {
            if (gameName === 'sm_poker'){
                faqGameNo = 2;
                setGameTab(0);

            } else if (gameName === 'sm_baduki'){
                faqGameNo = 3;
                setGameTab(1);

            } else if (gameName === 'sm_smpl'){
                faqGameNo = 4;
                setGameTab(2);
            }

            setTabGameNames(['SM 7포커', '바둑이']);
        }

        setFaqGameNo2(faqGameNo);
        qaData(0, faqGameNo);
        setQAContent(false);
        $('.service_tab_links').removeClass('active');

    }, [gameName, searchTxt, searchDiv]);


    return (
        <>
            <div className="qa_section">
                <div className="service_tab_box">
                    <div className="service_tab_a">
                        <div className="box_underR">
                            도움말 검색 결과
                            <img src={icon_plus} style={{padding: '8px 4px'}} alt="Icon plus"/>
                        </div>

                        {tabGameNames.map((name, index) => (
                            <button className={gameTab === index ? "service_tab_links_a active" : "service_tab_links_a"}
                                    onClick={event => changeGameQAPage(event, name)}>
                                {name}
                            </button>
                        ))}

                    </div>
                </div>
                <div className="qa_contents_box">
                    {faqItems.map((item, index) => (

                        <div className="qa_contents" onClick={event => showQAContent(index)}>
                            <button style={{fontSize: '16px', textAlign: 'left'}}>
                                <div className="qa_contents_inner_text"
                                     style={{width: '67px', textAlign: 'center'}}>
                                    {item.faq_no}
                                </div>
                                <div className="qa_contents_inner_text"
                                     style={{width: '115px', textAlign: 'center'}}>
                                    {gameNameSet(item.faq_game)}
                                </div>
                                <div className="qa_contents_inner_text"
                                     style={{width: '600px', paddingLeft: '20px'}}>
                                    {item.faq_title}
                                </div>
                            </button>
                        </div>
                    ))}
                </div>

                <div id='quit_box_id'></div>

                <div className="qa_notice_paging_box" style={{borderRight: 'none', borderLeft: 'none'}}>
                    {Array.from({length: numberOfPages}, (_, index) => (
                        <div key={index} onClick={event => changeQAPage(index)}>
                            <button
                                className={index === pageIndex ? 'qa_pagination_buttons' : 'qa_pagination_buttons_2'}>
                                {index + 1}
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            <div className="quit_box" style={{display: qaContent ? 'block': 'none'}}>
                <div className="quit_title_box">
                    <button className="quit_ok_no_button">Q</button>
                    <div className="quit_title">
                        <em style={{color: "#15a3e1"}}>{qaContent.faq_title}</em>
                    </div>
                </div>

                <button className="quit_ok_no_button" style={{backgroundColor: "#4e4e4e", marginTop: "34px"}}>A</button>
                <div className="quit_contents" dangerouslySetInnerHTML={{ __html: qaContent.faq_contents }}>
                </div>
            </div>
        </>
    );
};

export default QAPage;
